import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getDecodedToken, isTokenExpired } from "../../api/authUtils";

const ProtectedRoute: React.FC = () => {
  const decodedToken = getDecodedToken();
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    if (decodedToken) {
      setIsExpired(isTokenExpired());
    }
  }, [decodedToken]);

  if (isExpired) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
