import { Fragment } from "react";
import AsyncSelect from "react-select/async";
import { CarTypeOption, carTypeOptions } from "./data";

const filterTypes = (inputValue: string) => {
  return carTypeOptions.filter((i) =>
    i.label.toLowerCase().includes(inputValue.toLowerCase())
  );
};

const promiseOptions = (inputValue: string) =>
  new Promise<CarTypeOption[]>((resolve) => {
    setTimeout(() => {
      resolve(filterTypes(inputValue));
    }, 1000);
  });
export default function FindCar() {
  return (
    <Fragment>
      <div className="find-car">
        <div className="container">
          <div className="find-car-form">
            <h4 className="find-car-title">Let's Find Your Perfect Car</h4>
            <form action="#">
              <div className="row">
                <div className="col-lg-3">
                  <div className="form-group">
                    <label>Tipo</label>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      placeholder="Seleccionar"
                      loadOptions={promiseOptions}
                      defaultValue={{ value: "1", label: "Todos los tipos" }}
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group">
                    <label>Marca</label>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      placeholder="Seleccionar"
                      loadOptions={promiseOptions}
                      defaultValue={{ value: "1", label: "Todos los tipos" }}
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group">
                    <label>Modelo</label>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      placeholder="Seleccionar"
                      loadOptions={promiseOptions}
                      defaultValue={{ value: "1", label: "Todos los tipos" }}
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group">
                    <label>Año</label>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      placeholder="Seleccionar"
                      loadOptions={promiseOptions}
                      defaultValue={{ value: "1", label: "Todos los tipos" }}
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group">
                    <label>Kilometraje</label>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      placeholder="Seleccionar"
                      loadOptions={promiseOptions}
                      defaultValue={{ value: "1", label: "Todos los tipos" }}
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group">
                    <label>Rango de precios</label>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      placeholder="Seleccionar"
                      loadOptions={promiseOptions}
                      defaultValue={{ value: "1", label: "Todos los precios" }}
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group">
                    <label>Estilo</label>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      placeholder="Seleccionar"
                      loadOptions={promiseOptions}
                      defaultValue={{ value: "1", label: "Todos los estilos" }}
                    />
                  </div>
                </div>
                <div className="col-lg-3 align-self-end">
                  <button className="theme-btn" type="submit">
                    <span className="far fa-search"></span> Busca tu auto
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
