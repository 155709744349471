import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getDecodedToken, isTokenExpired } from "../../api/authUtils";

const Header: React.FC = () => {
  const navigate = useNavigate();
  const decodedToken = getDecodedToken();
  const [isExpired, setIsExpired] = useState(true);

  useEffect(() => {
    if (decodedToken) {
      setIsExpired(isTokenExpired());
    }
  }, [decodedToken, isExpired]);

  return (
    <header className="header">
      <div className="header-top">
        <div className="container">
          <div className="header-top-wrapper">
            <div className="header-top-left">
              <div className="header-top-contact">
                <ul>
                  <li>
                    <a href="mailto:info@example.com">
                      <i className="far fa-envelopes"></i>
                      info@auto-tianguis.com
                    </a>
                  </li>
                  <li>
                    <a href="+2 123 654 7898">
                      <i className="fa-brands fa-whatsapp"></i> +2 123 654 7898
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="header-top-right">
              {isExpired && (
                <div className="header-top-link">
                  <button onClick={() => navigate("/login")}>
                    Iniciar Sesion
                  </button>

                  <button onClick={() => navigate("/register")}>
                    Registrarse
                  </button>
                </div>
              )}
              <div className="header-top-social">
                <span>Siguenos: </span>
                <a href="#">
                  <i className="fab fa-facebook"></i>
                </a>
                <a href="#">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="#">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="#">
                  <i className="fab fa-linkedin"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
