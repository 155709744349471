import React, { useEffect, useState } from "react";
import { Menus } from "../Navbar/data";
import { getDecodedToken, isTokenExpired } from "../../api/authUtils";
import Menu from "../Menu/Menu";
import { MenuPermissions } from "../../types/users";

interface MenuProps extends MenuPermissions {
  classType: string; // Add the string prop
}

const Navbar: React.FC<MenuProps> = ({
  menuItems,
  onUpdateContent,
  classType,
}) => {
  const decodedToken = getDecodedToken();
  const [isExpired, setIsExpired] = useState(true);

  useEffect(() => {
    if (decodedToken) {
      setIsExpired(isTokenExpired());
    }
  }, [decodedToken]);

  const handleSubmit = (activeContent: string) => {
    onUpdateContent(activeContent);
  };
  return (
    <div className="main-navigation">
      <nav className="navbar navbar-expand-lg">
        <div className="container position-relative">
          <a className="navbar-brand" href="index.html">
            <img src="assets/img/logo/logo.png" alt="logo" />
          </a>
          <div className="mobile-menu-right">
            {!isExpired && (
              <div className="nav-right-account">
                <div className="dropdown">
                  <div data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="assets/img/account/user.jpg" alt="" />
                  </div>
                  <ul className="dropdown-menu dropdown-menu-end">
                    {
                      <Menu
                        menuItems={menuItems}
                        onUpdateContent={onUpdateContent}
                        classType={classType}
                      />
                    }
                  </ul>
                </div>
              </div>
            )}
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#main_nav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-mobile-icon">
                <i className="far fa-bars"></i>
              </span>
            </button>
          </div>
          <div className="collapse navbar-collapse" id="main_nav">
            <ul className="navbar-nav">
              {Menus.map(
                (item, index) =>
                  item.isVisible && (
                    <li
                      className={
                        "nav-item " + (item.Submenu?.length ? "dropdown" : "")
                      }
                      key={index}
                    >
                      {item.icon && <img src={item.icon} alt={item.label} />}
                      <a
                        className={
                          "nav-link " +
                          (item.Submenu?.length ? "dropdown-toggle" : "")
                        }
                        href={item.path}
                        data-bs-toggle={item.Submenu?.length ? "dropdown" : ""}
                      >
                        {item.label}
                      </a>
                      {item.Submenu && (
                        <ul className="dropdown-menu fade-down">
                          {item.Submenu.map((submenuItem, i) => (
                            <li key={i}>
                              <a
                                className="dropdown-item"
                                onClick={() => handleSubmit(submenuItem?.path)}
                              >
                                {submenuItem.label}
                              </a>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  )
              )}
            </ul>
            <div className="nav-right">
              {!isExpired && (
                <div className="nav-right-account">
                  <div className="dropdown">
                    <div data-bs-toggle="dropdown" aria-expanded="false">
                      <img src="assets/img/account/user.jpg" alt="" />
                    </div>
                    <ul className="dropdown-menu dropdown-menu-end">
                      {
                        <Menu
                          menuItems={menuItems}
                          onUpdateContent={onUpdateContent}
                          classType={classType}
                        />
                      }
                    </ul>
                  </div>
                </div>
              )}
              <div className="nav-right-btn mt-2">
                <a href="#" className="theme-btn">
                  <span className="far fa-plus-circle"></span>Vende tu vehiculo
                </a>
              </div>
              {isExpired && (
                <div className="sidebar-btn">
                  <button type="button" className="nav-right-link">
                    <i className="far fa-bars-sort"></i>
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="search-area">
            <form action="#">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type Keyword..."
                />
                <button type="submit" className="search-icon-btn">
                  <i className="far fa-search"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
