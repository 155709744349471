import React, { useState, Fragment, useEffect } from "react";
import Hero from "../../components/Hero/Hero";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import UserSidebar from "../../components/UserSidebar/UserSidebar";
import Widget from "../../components/Widget/Widget";
import { getDecodedToken, timeUntilExpiry } from "../../api/authUtils";
import { useNavigate } from "react-router-dom";
import { MenuPermission, UserDetails } from "../../types/users";
import { toast } from "react-toastify";
import Navbar from "../../components/Navbar/Navbar";
import { useAuth } from "../../api/auth/auth-context";

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const decodedToken = getDecodedToken();
  const [activeContent, setActiveContent] = useState<string>("home");
  const [userDetails, setUserDetails] = useState<UserDetails | undefined>(
    () => {
      if (decodedToken) {
        const userDetail: UserDetails = JSON.parse(
          JSON.parse(JSON.stringify(decodedToken?.userDetails))
        );
        return userDetail;
      }
      return undefined;
    }
  );
  const [permissions, setPermissions] = useState<MenuPermission[] | undefined>(
    () => {
      if (decodedToken) {
        const userPermissions: MenuPermission[] = JSON.parse(
          JSON.parse(JSON.stringify(decodedToken?.permissions))
        );
        return userPermissions;
      }
      return undefined;
    }
  );

  useEffect(() => {
    if (decodedToken) {
      const expirationTime = timeUntilExpiry();

      if (expirationTime > 0) {
        const timeoutId = setTimeout(() => {
          toast.info("Sesion expirada... redireccionando a pagina principal", {
            autoClose: 5000,
          });
          navigate("/");
        }, expirationTime);

        return () => clearTimeout(timeoutId);
      } else {
        toast.info("Sesion expirada... redireccionando a pagina principal", {
          autoClose: 3000,
        });
        navigate("/");
      }
    } else {
      navigate("/");
    }

    if (!userDetails) {
      setUserDetails(userDetails);
    }
    if (!permissions) {
      setPermissions(permissions);
    }
  }, [decodedToken, userDetails, permissions, navigate]);

  const updateContent = (content: string) => {
    if (content !== activeContent) {
      // Prevent unnecessary state updates
      if (content === "logout") {
        logout();
      }
      setActiveContent(content);
    }
  };

  return (
    <Fragment>
      <Header />
      <Navbar
        menuItems={permissions}
        onUpdateContent={updateContent}
        classType={"dropdown-item"}
      />
      <main className="main">
        <Hero />
        <UserSidebar
          onUpdateContent={updateContent}
          data={userDetails}
          menuItems={permissions}
        />
        <div className="col-lg-9">
          <div className="user-profile-wrapper">
            {activeContent === "dashboard" &&
              decodedToken &&
              (JSON.parse(JSON.stringify(decodedToken, ["userType"]))
                .userType === "Administrador" ||
                (JSON.parse(JSON.stringify(decodedToken, ["userType"]))
                  .userType === "SuperAdministrador" && (
                  <div className="row">
                    <Widget
                      counter={50}
                      name="Total de Usuarios"
                      icon="fal fa-users"
                      colorClass="dashboard-widget-color-2"
                    />
                    <Widget
                      counter={50}
                      name="Total de Membresias"
                      icon="fal fa-crown"
                      colorClass="dashboard-widget-color-1"
                    />
                    <Widget
                      counter={50}
                      name="Total de Publicaciones"
                      icon="fal fa-layer-group"
                      colorClass="dashboard-widget-color-8"
                    />
                  </div>
                )))}
          </div>
        </div>
      </main>
      <Footer />
      <a href="#" id="scroll-top">
        <i className="far fa-arrow-up"></i>
      </a>
    </Fragment>
  );
};

export default Dashboard;
