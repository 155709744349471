import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../api/auth/auth-context";
import { toast } from "react-toastify"; // Import toast for notifications
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Navbar from "../../components/Navbar/Navbar";
import { getDecodedToken, isTokenExpired } from "../../api/authUtils";
import { useNavigate } from "react-router-dom";
import Hero from "../../components/Hero/Hero";
import CustomGoogleButton from "../../components/Common/CustomGoogleButton";
import CustomFacebookButton from "../../components/Common/CustomFacebookButton";

interface LoginFormData {
  username: string;
  password: string;
}

const Login: React.FC = () => {
  const { loginWithCredentials, registrar } = useAuth();
  const navigate = useNavigate();
  const decodedToken = getDecodedToken();
  const [loading, setLoading] = useState<boolean>(false);
  const [activeContent, setActiveContent] = useState<string>("home");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormData>();

  useEffect(() => {
    if (decodedToken) {
      const isExpired = isTokenExpired();
      if (!isExpired) {
        navigate("/");
      }
    }
  }, [decodedToken, navigate]);

  const handleTraditionalLogin = async (data: LoginFormData) => {
    const { username, password } = data;
    setLoading(true);
    try {
      await loginWithCredentials(username, password);
    } catch (error) {
      console.error(
        "Usuario o Password incorrectos. Favor de verificar",
        error
      );

      toast.error("Usuario o Password incorrectos. Favor de verificar");
    } finally {
      setLoading(false);
    }
  };

  const handleRegister = async () => {
    await registrar();
  };

  const updateContent = (content: string) => {
    if (content !== activeContent) {
      // Prevent unnecessary state updates
      setActiveContent(content);
    }
  };

  return (
    <Fragment>
      <Header />
      <Navbar
        menuItems={[]}
        onUpdateContent={updateContent}
        classType={"dropdown-item"}
      />
      <main className="main">
        <Hero />
        <div className="login-area py-120">
          <div className="container">
            <div className="col-md-10 col-lg-6 mx-auto">
              <div className="login-form">
                <div className="login-header">
                  <img src="assets/img/logo/logo.png" alt="" />
                  <p>Ingresar con usuario de autotianguis</p>
                </div>
                <form onSubmit={handleSubmit(handleTraditionalLogin)}>
                  <div className="form-group">
                    <label>Usuario</label>
                    <input
                      className="form-control"
                      type="text"
                      {...register("username", {
                        required: "Usuario es requerido",
                      })}
                      placeholder="Ingresar usuario"
                    />
                    {errors.username && <span>{errors.username.message}</span>}
                  </div>
                  <div className="form-group">
                    <label>Contraseña</label>
                    <input
                      className="form-control"
                      placeholder="Ingresar Contraseña"
                      type="password"
                      {...register("password", {
                        required: "password es requerido",
                      })}
                    />
                    {errors.password && <span>{errors.password.message}</span>}
                  </div>
                  <div className="d-flex justify-content-between mb-4">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="remember"
                      />
                      <label className="form-check-label" htmlFor="remember">
                        Recordar
                      </label>
                    </div>
                    <a href="forgot-password.html" className="forgot-pass">
                      Olvido su contraseña?
                    </a>
                  </div>
                  <div className="d-flex align-items-center">
                    <button
                      type="submit"
                      className="theme-btn"
                      disabled={loading}
                    >
                      <i className="far fa-sign-in"></i> Ingresar
                    </button>
                  </div>
                </form>
                <div className="login-footer">
                  <p>
                    No esta registrado?{" "}
                    <a onClick={handleRegister}>Registrarse</a>
                  </p>
                  <div className="social-login">
                    <p>Ingresar con redes sociales</p>
                    <div className="social-login-list">
                      {/* Google Login */}
                      <CustomGoogleButton />
                      <CustomFacebookButton />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <a href="#" id="scroll-top">
        <i className="far fa-arrow-up"></i>
      </a>
    </Fragment>
  );
};

export default Login;
