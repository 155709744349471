import React from "react";
import { WidgetProps } from "../../types/common";

const Widget: React.FC<WidgetProps> = ({ counter, name, icon, colorClass }) => {
  return (
    <div className="col-md-6 col-lg-4">
      <div className={"dashboard-widget " + colorClass}>
        <div className="dashboard-widget-info">
          <h1>{counter}</h1>
          <span>{name}</span>
        </div>
        <div className="dashboard-widget-icon">
          <i className={icon}></i>
        </div>
      </div>
    </div>
  );
};

export default Widget;
