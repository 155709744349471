export type CarTypeOption = {
  readonly value: string;
  readonly label: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
};

export const carTypeOptions: readonly CarTypeOption[] = [
  { value: "1", label: "Todos los tipos" },
  { value: "2", label: "Nuevos" },
  { value: "3", label: "Seminuevos" },
];
