import React from "react";
import { MenuPermissions } from "../../types/users";

interface MenuProps extends MenuPermissions {
  classType: string; // Add the string prop
}

const Menu: React.FC<MenuProps> = ({
  menuItems,
  onUpdateContent,
  classType,
}) => {
  const handleSubmit = (activeContent: string) => {
    onUpdateContent(activeContent);
  };
  return menuItems?.map(
    (item, index) =>
      item.CanRead && (
        <li key={index}>
          <a
            className={`${classType} ${index == 0 ? " active" : ""}`}
            onClick={() => handleSubmit(item.Path)}
          >
            <i className={item.ImageIcon}></i>
            {item.Name}
          </a>
        </li>
      )
  );
};

export default Menu;
