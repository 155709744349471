import { Fragment } from "react";
import BannerImage from "../../assets/img/slider/slider-1.jpg";

export default function Hero() {
  return (
    <Fragment>
      <div className="hero-section">
        <div className="hero-slider owl-carousel owl-theme">
          <div
            className="hero-single"
            style={{
              backgroundImage: `url(${BannerImage})`,
            }}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-12 col-lg-6">
                  <div className="hero-content">
                    <h6
                      className="hero-sub-title"
                      data-animation="fadeInUp"
                      data-delay=".25s"
                    >
                      Welcome To Motex!
                    </h6>
                    <h1
                      className="hero-title"
                      data-animation="fadeInRight"
                      data-delay=".50s"
                    >
                      Best Way To Find Your <span>Dream</span> Car
                    </h1>
                    <p data-animation="fadeInLeft" data-delay=".75s">
                      There are many variations of passages orem psum available
                      but the majority have suffered alteration in some form by
                      injected humour.
                    </p>
                    <div
                      className="hero-btn"
                      data-animation="fadeInUp"
                      data-delay="1s"
                    >
                      <a href="#" className="theme-btn">
                        About More<i className="fas fa-arrow-right-long"></i>
                      </a>
                      <a href="#" className="theme-btn theme-btn2">
                        Learn More<i className="fas fa-arrow-right-long"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-6">
                  <div className="hero-right">
                    <div className="hero-img">
                      <img
                        src="assets/img/slider/hero-1.png"
                        alt=""
                        data-animation="fadeInRight"
                        data-delay=".25s"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
