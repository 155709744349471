import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../api/axiosInstance";

const Register: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [username, setUserName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleRegister = async () => {
    setLoading(true);
    try {
      await axiosInstance.post("/auth/register", { email, password, username });
      alert("Registro exitoso! Favor de loguearse.");
      navigate("/");
    } catch (error) {
      console.error("Registro erroneo", error);
      alert("Registro erroneo. Intente de nuevo.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h1>Registrarse</h1>
      <input
        type="text"
        placeholder="Usuario"
        value={username}
        onChange={(e) => setUserName(e.target.value)}
      />
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleRegister} disabled={loading}>
        Registrarse
      </button>
    </div>
  );
};

export default Register;
