import { Fragment, useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Landing from "../../components/Common/Landing";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import { getDecodedToken, isTokenExpired } from "../../api/authUtils";
import { MenuPermission } from "../../types/users";
import { useAuth } from "../../api/auth/auth-context";

export default function Home() {
  const { logout } = useAuth();
  const decodedToken = getDecodedToken();
  const [activeContent, setActiveContent] = useState<string>("home");
  const [isExpired, setIsExpired] = useState(true);
  const [permissions, setPermissions] = useState<MenuPermission[] | undefined>(
    () => {
      if (decodedToken) {
        const userPermissions: MenuPermission[] = JSON.parse(
          JSON.parse(JSON.stringify(decodedToken?.permissions))
        );
        return userPermissions;
      }
      return undefined;
    }
  );

  useEffect(() => {
    if (decodedToken) {
      setIsExpired(isTokenExpired());
    }

    if (!permissions) {
      setPermissions(permissions);
    }
  }, [decodedToken, permissions]);

  const updateContent = (content: string) => {
    if (content !== activeContent) {
      // Prevent unnecessary state updates
      if (content === "logout") {
        logout();
      }
      setActiveContent(content);
    }
  };

  return (
    <Fragment>
      <Header />
      <Navbar
        menuItems={!isExpired ? permissions : []}
        onUpdateContent={updateContent}
        classType="dropdown-item"
      />
      <Landing />
      <Footer />
      <a href="#" id="scroll-top">
        <i className="far fa-arrow-up"></i>
      </a>
    </Fragment>
  );
}
