import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { useAuth } from "../../api/auth/auth-context";

const CustomGoogleButton: React.FC = () => {
  const { loginWithGoogle } = useAuth();

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => loginWithGoogle(tokenResponse.access_token),
    onError: () => console.log("Google Login Failed"),
  });

  return (
    <a onClick={() => googleLogin()}>
      <i className="fab fa-google"></i>
    </a>
  );
};

export default CustomGoogleButton;
