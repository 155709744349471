import React, { createContext, useContext, useState, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInstance";
import { toast } from "react-toastify"; // Import toast for showing notifications
import { PostData } from "../../types/users";

interface AuthContextType {
  token: string | null;
  loading: boolean;
  isAuthenticated: boolean;
  loginWithGoogle: (googleCredential: string) => Promise<void>;
  loginWithFacebook: (facebookCredential: string) => Promise<void>;
  loginWithCredentials: (username: string, password: string) => Promise<void>;
  registrar: () => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const loginWithGoogle = async (googleCredential: string) => {
    try {
      const response = await axiosInstance.post("/api/auth/google", {
        credential: googleCredential,
      });

      localStorage.setItem("authToken", response.data.token); // Save token to localStorage

      // Redirect based on user registration status
      if (response.data.token) {
        setIsAuthenticated(true);
        navigate("/dashboard"); // Redirect to the dashboard if user is registered
      } else {
        navigate("/register"); // Redirect to the registration page if user is not registered
      }
    } catch (error) {
      console.error("Google login failed", error);
      toast.error("Google login invalido! Favor de reintentar.", {
        autoClose: 5000,
      }); // Show error notification
      navigate("/login"); // Redirect to login page if something goes wrong
    }
  };

  const loginWithFacebook = async (facebookCredential: string) => {
    try {
      const response = await axiosInstance.post("/api/auth/facebook", {
        credential: facebookCredential,
      });

      localStorage.setItem("authToken", response.data.token); // Save token to localStorage

      // Redirect based on user registration status
      if (response.data.token) {
        navigate("/dashboard"); // Redirect to the dashboard if user is registered
      } else {
        navigate("/register"); // Redirect to the registration page if user is not registered
      }
    } catch (error) {
      console.error("Facebook login failed", error);
      toast.error("Facebook login invalido! Favor de reintentar.", {
        autoClose: 5000,
      }); // Show error notification
      navigate("/login"); // Redirect to login page if something goes wrong
    }
  };

  const loginWithCredentials = async (username: string, password: string) => {
    const data: PostData = { UserName: username, Password: password };
    try {
      setLoading(true);
      const response = await axiosInstance.post("/api/auth/login", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setToken(response.data.token);
      localStorage.setItem("authToken", response.data.token); // Save token to localStorage

      // Redirect based on user registration status
      if (response.data.token) {
        toast.success("Inicio de sesion exitoso...", {
          autoClose: 2000,
        });
        setTimeout(() => {
          navigate("/dashboard");
        }, 2000);
      } else {
        navigate("/register"); // Redirect to the registration page if user is not registered
      }
    } catch (error) {
      setToken(null);
      console.error("Traditional login failed", error);
      toast.error("Usuario o contraseña invalidos. favor de verificar.", {
        autoClose: 3000,
      }); // Show error notification
      navigate("/login"); // Redirect to login page if something goes wrong
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    localStorage.removeItem("authToken");
    setIsAuthenticated(false);
    toast.info("Cerrando su sesion...", {
      autoClose: 2000,
    });
    setTimeout(() => {
      navigate("/");
    }, 5000);
  };

  const registrar = () => {
    navigate("/register");
  };

  return (
    <AuthContext.Provider
      value={{
        token,
        loading,
        isAuthenticated,
        loginWithGoogle,
        loginWithFacebook,
        loginWithCredentials,
        logout,
        registrar,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
