import { jwtDecode } from "jwt-decode";

interface JwtPayload {
  exp?: number;
  permissions?: string;
  sub?: string;
  userDetails?: string;
}

/**
 * Reads and decodes the JWT from localStorage.
 * @param key The key used to store the token in localStorage (default is 'token')
 * @returns The decoded payload or null if the token is missing or invalid
 */
export const getDecodedToken = (
  key: string = "authToken"
): JwtPayload | null => {
  try {
    const token = localStorage.getItem(key);
    if (!token) {
      console.warn("Token not found in localStorage");
      return null;
    }

    const decoded = jwtDecode<JwtPayload>(token);
    return decoded;
  } catch (error) {
    console.error("Failed to decode token:", error);
    return null;
  }
};

/**
 * Checks if the token stored in localStorage is expired.
 * @param key The key used to store the token in localStorage (default is 'token')
 * @returns True if the token is expired, false otherwise
 */
export const isTokenExpired = (key: string = "authToken"): boolean => {
  const decoded = getDecodedToken(key);
  if (!decoded || !decoded.exp) return true;

  const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
  return decoded.exp < currentTime; // True if token is valid
};

export const timeUntilExpiry = (key: string = "authToken"): number => {
  const decoded = getDecodedToken(key);
  if (!decoded || !decoded.exp) return 0;

  const currentTime = Math.floor(Date.now() / 1000);
  return (decoded.exp - currentTime) * 1000;
};
