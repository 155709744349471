import { MenuItem } from "../../types/common";
export const Menus: MenuItem[] = [
  {
    name: "Home",
    label: "Inicio",
    isFixed: true,
    isDisabled: false,
    isVisible: true,
    path: "/",
  },
  {
    name: "Search",
    label: "Buscar autos",
    isFixed: true,
    isDisabled: false,
    isVisible: true,
    path: "search",
  },
  {
    name: "Inventory",
    label: "Inventario",
    Submenu: [
      {
        name: "Semi",
        label: "Seminuevos",
        isFixed: true,
        isDisabled: false,
        path: "",
      },
      {
        name: "New",
        label: "Nuevos",
        isFixed: true,
        isDisabled: false,
        path: "",
      },
      {
        name: "Financing",
        label: "A Credito",
        isFixed: true,
        isDisabled: false,
        path: "",
      },
      {
        name: "Dealer",
        label: "De Agencia",
        isFixed: true,
        isDisabled: false,
        path: "",
      },
    ],
    isFixed: true,
    isDisabled: false,
    isVisible: true,
    path: "",
  },
  {
    name: "About Us",
    label: "Conocenos",
    Submenu: [
      {
        name: "Us",
        label: "Quienes somos?",
        isFixed: true,
        isDisabled: false,
        path: "",
      },
      {
        name: "How",
        label: "Como funciona?",
        isFixed: true,
        isDisabled: false,
        path: "",
      },
      {
        name: "Benefits",
        label: "Beneficios",
        isFixed: true,
        isDisabled: false,
        path: "",
      },
      {
        name: "Warranty",
        label: "Garantias",
        isFixed: true,
        isDisabled: false,
        path: "",
      },
      {
        name: "PP",
        label: "Planes de proteccion",
        isFixed: true,
        isDisabled: false,
        path: "",
      },
      {
        name: "Cerficates",
        label: "Certificados de servicio",
        isFixed: true,
        isDisabled: false,
        path: "",
      },
      {
        name: "Updates",
        label: "Recibir actualizaciones",
        isFixed: true,
        isDisabled: false,
        path: "",
      },
    ],
    isFixed: true,
    isDisabled: false,
    isVisible: true,
    path: "",
  },
  {
    name: "Memberships",
    label: "Membresias",
    Submenu: [
      {
        name: "Plans",
        label: "Precios",
        isFixed: true,
        isDisabled: false,
        path: "",
      },
      {
        name: "Contract",
        label: "Adquirir membresia",
        isFixed: true,
        isDisabled: false,
        path: "",
      },
    ],
    isFixed: true,
    isDisabled: false,
    isVisible: true,
    path: "",
  },
  {
    name: "Financing",
    label: "Financiamiento",
    Submenu: [
      {
        name: "FinancingPlans",
        label: "Conoce nuestros planes",
        isFixed: true,
        isDisabled: false,
        path: "",
      },
      {
        name: "Quote",
        label: "Cotizar",
        isFixed: true,
        isDisabled: false,
        path: "",
      },
    ],
    isFixed: true,
    isDisabled: false,
    isVisible: true,
    path: "",
  },
  {
    name: "Contact",
    label: "Contacto",
    Submenu: [
      {
        name: "Help",
        label: "Ayuda",
        isFixed: true,
        isDisabled: false,
        path: "",
      },
      {
        name: "FAQ",
        label: "Preguntas Frecuentes",
        isFixed: true,
        isDisabled: false,
        path: "",
      },
      {
        name: "Reach",
        label: "Contactanos",
        isFixed: true,
        isDisabled: false,
        path: "",
      },
    ],
    isFixed: true,
    isDisabled: false,
    isVisible: true,
    path: "",
  },
  {
    name: "Dashboard",
    label: "Administracion",
    isFixed: true,
    isDisabled: false,
    isVisible: false,
    path: "",
  },
];
