import { Fragment } from "react";
import Hero from "../Hero/Hero";
import FindCar from "../Forms/FindCar";

const LandingPage: React.FC = () => {
  return (
    <Fragment>
      <main className="main">
        <Hero />
        <FindCar />
      </main>
    </Fragment>
  );
};

export default LandingPage;
