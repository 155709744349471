import React from "react";
import { MenuPermissions, UserChildProps } from "../../types/users";
import Menu from "../Menu/Menu";

const UserSidebar: React.FC<UserChildProps & MenuPermissions> = ({
  data,
  onUpdateContent,
  menuItems,
}) => {
  return (
    <div className="user-profile py-120">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="user-profile-sidebar">
              <div className="user-profile-sidebar-top">
                <div className="user-profile-img">
                  <img src={data?.ProfileImage} alt="" />
                  <button type="button" className="profile-img-btn">
                    <i className="far fa-camera"></i>
                  </button>
                  <input type="file" className="profile-img-file" />
                </div>
                <h5>{data?.FirstName}</h5>
                <p>{data?.Email}</p>
              </div>
              <ul className="user-profile-sidebar-list">
                {
                  <Menu
                    menuItems={menuItems}
                    onUpdateContent={onUpdateContent}
                    classType=""
                  />
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSidebar;
