import React from "react";
import { useAuth } from "../../api/auth/auth-context";

const CustomFacebookButton: React.FC = () => {
  const { loginWithFacebook } = useAuth();

  return (
    <a onClick={() => loginWithFacebook}>
      <i className="fab fa-facebook-f"></i>
    </a>
  );
};

export default CustomFacebookButton;
